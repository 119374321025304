import axios from "axios";
import { API, BEARER } from "../constant";
import { getToken } from "../helpers";

const token = getToken();

export const getAlbums = async () => {
  const response = await axios
    .get(`${API}/api/albums?pagination[pageSize]=100`)
    .then(({ data }) => data.data)
    .catch((err) => console.error(err))
    .finally(() => {});
  return response;
};

export const postAlbum = async (name) => {
  const response = await axios
    .post(
      `${API}/api/albums`,
      { data: { name } },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return response;
};
