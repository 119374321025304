import styled from "styled-components";
import { usePictureURL } from "../hooks/usePictureURL.js";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const StyledInformations = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 50;
  background-color: #00000080;
`;

const StyledAlbum = styled.div`
  position: relative;
  z-index: 1000;
  padding-top: 10px;
  margin: auto;
  width: 60px;
  text-align: center;
  font-size: 0.75rem;
  color: #cbd5e1;
`;

const StyledTitle = styled.div`
  color: #cbd5e1;
`;

const StyledDescription = styled.div`
  font-size: 0.75rem;
  color: #ffffffb3;
`;

const GalleryView = ({ album, pictures }) => {
  const { pictureURL } = usePictureURL();

  return (
    !!pictures.length && (
      <PhotoProvider
        overlayRender={({ index }) => {
          return (
            <>
              <StyledAlbum>{album}</StyledAlbum>
              <StyledInformations>
                <StyledTitle>{pictures[index].attributes.title}</StyledTitle>
                <StyledDescription>
                  {pictures[index].attributes.description}
                </StyledDescription>
              </StyledInformations>
            </>
          );
        }}
      >
        {pictures.map((picture) => (
          <PhotoView
            key={`picture${picture.id}`}
            src={pictureURL(picture, "lg")}
            onVisibleChange={{ visible: true }}
          >
            <div id={picture.id}></div>
          </PhotoView>
        ))}
      </PhotoProvider>
    )
  );
};

export default GalleryView;
