import styled from "styled-components";
import React, { useState } from "react";
import { useAuthContext } from "../auth/AuthContext";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const Container = styled.div`
  display: ${({ $logged }) => ($logged ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  height: 75vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Field = styled(TextField)`
  margin: 5px 0 !important;
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin: 10px !important;
`;

const Progress = styled.div`
  z-index: 1000;
  background-color: #ffffff3b;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { user, isLoading, error, login } = useAuthContext();

  const handleSubmitConnect = async (event) => {
    event.preventDefault();
    login({ identifier: email, password });
  };

  return (
    <Container $logged={!!user} style={{ color: "white" }}>
      {isLoading && (
        <Progress>
          <CircularProgress sx={{ margin: "auto" }} />
        </Progress>
      )}
      <Form onSubmit={handleSubmitConnect}>
        <Field
          label="Email"
          variant="outlined"
          type="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <Field
          label="Mot de passe"
          variant="outlined"
          type="password"
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <StyledButton
          variant="outlined"
          color="success"
          size="small"
          type="submit"
        >
          Connexion
        </StyledButton>
        {error && <span>{error}</span>}
      </Form>
    </Container>
  );
};

export default LoginForm;
