import axios from "axios";
import { API, BEARER } from "../constant";
import { getToken } from "../helpers";

const token = getToken();

export const getPicture = async (id) => {
  const response = await axios
    .get(`${API}/api/pictures/${id}?populate=*`)
    .then(({ data }) => data.data)
    .catch((err) => console.error(err))
    .finally(() => {});
  return response;
};

export const getPictures = async ({ count, page, album, home }) => {
  let filter = "";

  if (home)
    filter = `&pagination[pageSize]=100&filters[display][$eq]=true&filters[home][$eq]=true`;
  else if (album && count)
    switch (album) {
      case "full":
        filter = `&pagination[pageSize]=0&filters[display][$eq]=true`;
        break;
      default:
        filter = `&pagination[pageSize]=0&filters[display][$eq]=true&filters[album][name][$eq]=${album}`;
    }
  else if (album && page)
    switch (album) {
      case "full":
        filter = `&pagination[pageSize]=100&pagination[page]=${page}&filters[display][$eq]=true`;
        break;
      default:
        filter = `&pagination[pageSize]=100&pagination[page]=${page}&filters[display][$eq]=true&filters[album][name][$eq]=${album}`;
    }
  else if (count) filter = `&pagination[pageSize]=0`;
  else if (page) filter = `&pagination[pageSize]=100&pagination[page]=${page}`;

  const response = await axios
    .get(`${API}/api/pictures?populate=*${filter}`)
    .then(({ data }) => data)
    .catch((err) => console.error(err))
    .finally(() => {});
  return response;
};

export const postPicture = async (picture) => {
  const result = await axios
    .post(
      `${API}/api/pictures`,
      { data: picture },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};

export const putPicture = async (picture, id) => {
  const result = await axios
    .put(
      `${API}/api/pictures/${id}`,
      { data: picture },
      { headers: { Authorization: `${BEARER} ${token}` } }
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};

export const deletePicture = async (id) => {
  const result = await axios
    .delete(`${API}/api/pictures/${id}`, {
      headers: { Authorization: `${BEARER} ${token}` },
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};
