import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HomeList from "../components/HomeList";

document.addEventListener("contextmenu", (event) => event.preventDefault());

const Page = styled.div`
  min-height: calc(100% - 50px);
  margin: auto;
  padding: 0 5px;
  @media (min-width: 641px) {
    padding: 0 50px;
  }
`;

const Home = () => {
  const meta = {
    title: "SHOT BY BEE Photographies",
    description:
      "Photographe passionnée et créative, j'ai à cœur d'immortaliser la magie et l'émotion de vos instants précieux, qu'ils soient professionnels ou privés.",
    image: "https://shotbybee.fr/assets/profile_resized.jpg",
    url: "https://shotbybee.fr",
  };

  return (
    <HelmetProvider>
      <Page>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:locale" content="fr_FRANCE" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={meta.image} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
        </Helmet>
        <HomeList />
      </Page>
    </HelmetProvider>
  );
};

export default Home;
