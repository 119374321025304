import styled from "styled-components";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAuthContext } from "../auth/AuthContext";
import { getAlbums } from "../services/AlbumService.js";
import { getPictures } from "../services/PictureService.js";

import LoginForm from "../components/LoginForm";
import AlbumAccordion from "../components/AlbumAccordion";
import Upload from "../components/Upload";
import DataCard from "../components/DataCard.js";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Dialog from "@mui/material/Dialog";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Page = styled.div`
  min-height: calc(100% - 50px);
  margin: auto;
  padding: 0 5px;
  @media (min-width: 641px) {
    padding: 0 50px;
  }
`;

const Admin = () => {
  const meta = {
    title: "SHOT BY BEE | Admin",
    description:
      "Photographe passionnée et créative, j'ai à cœur d'immortaliser la magie et l'émotion de vos instants précieux, qu'ils soient professionnels ou privés.",
    image: "https://shotbybee.fr/assets/profile_resized.jpg",
    url: "https://shotbybee.fr",
  };

  const [pictures, setPictures] = useState([]);
  const [albumsTitles, setAlbumsTitles] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedPicture, setSelectedPicture] = useState(false);
  const [updated, setUpdated] = useState();
  const [deleted, setDeleted] = useState();
  const [value, setValue] = useState("1");
  const [expanded, setExpanded] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: "",
    message: "",
  });

  const { user } = useAuthContext();

  useEffect(() => {
    getAlbums().then((data) =>
      setAlbumsTitles(data.map((d) => d.attributes.name))
    );
    getPictures({ count: true }).then(
      ({
        meta: {
          pagination: { total },
        },
      }) => {
        Promise.all(
          [...Array((total - (total % 100)) / 100 + 1).keys()].map((i) =>
            getPictures({ page: i + 1 })
          )
        ).then((data) =>
          setPictures(data.reduce((acc, curr) => [...acc, ...curr.data], []))
        );
      }
    );
  }, []);

  useEffect(() => {
    if (updated) {
      setPictures(
        pictures.map((picture) =>
          picture.id === updated.id ? updated : picture
        )
      );
      setUpdated();
    }
  }, [updated, setUpdated, pictures]);

  useEffect(() => {
    if (deleted) {
      setPictures(
        pictures.reduce(
          (acc, curr) => [...acc, ...(curr.id === deleted ? [] : [curr])],
          []
        )
      );
      setDeleted();
    }
  }, [deleted, setDeleted, pictures]);

  return (
    <HelmetProvider>
      <Page>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:locale" content="fr_FRANCE" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={meta.image} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
        </Helmet>
        <LoginForm />
        {user && (
          <>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={(event, newValue) => setValue(newValue)}>
                    <Tab label="Albums" value="1" />
                    <Tab label="Téléchargement" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <AlbumAccordion
                    album={"SANS ALBUM"}
                    pictures={pictures.filter((p) => !p.attributes.album.data)}
                    expanded={expanded}
                    setExpanded={(isExpanded, album) =>
                      setExpanded(isExpanded ? album : false)
                    }
                    setOpenUpdate={() => setOpenUpdate(true)}
                    setSelectedPicture={(data) => setSelectedPicture(data)}
                  />
                  {albumsTitles.map((albumTitle, index) => (
                    <AlbumAccordion
                      key={`folder${index}`}
                      album={albumTitle}
                      pictures={pictures.filter(
                        (p) =>
                          p.attributes.album.data?.attributes.name ===
                          albumTitle
                      )}
                      expanded={expanded}
                      setExpanded={(isExp, alb) =>
                        setExpanded(isExp ? alb : false)
                      }
                      setOpenUpdate={() => setOpenUpdate(true)}
                      setSelectedPicture={(data) => setSelectedPicture(data)}
                    />
                  ))}
                </TabPanel>
                <TabPanel value="2">
                  <Upload />
                </TabPanel>
              </TabContext>
            </Box>
            <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)}>
              <DataCard
                selectedPicture={selectedPicture}
                setUpdated={setUpdated}
                setDeleted={setDeleted}
                close={() => setOpenUpdate(false)}
                setSnackbar={(s) => setSnackbar(s)}
              />
            </Dialog>
            <Snackbar
              open={snackbar.open}
              autoHideDuration={4000}
              onClose={() =>
                setSnackbar({ open: false, type: "", message: "" })
              }
            >
              <Alert
                severity={snackbar.type || "info"}
                variant="filled"
                sx={{ width: "100%" }}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </>
        )}
      </Page>
    </HelmetProvider>
  );
};

export default Admin;
