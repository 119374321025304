import styled from "styled-components";
import { useRef, useState, useEffect } from "react";
import { postFiles } from "../services/FileService.js";
import { postPicture } from "../services/PictureService.js";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const Progress = styled.div`
  z-index: 1000;
  background-color: #ffffff3b;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Upload = () => {
  const aRef = useRef(null);
  const [uploading, setUploading] = useState(null);
  const [fileInput, setFileInput] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (fileInput.length)
      setFiles((f) => [
        ...f,
        ...fileInput.map((file) => ({
          file,
          url: URL.createObjectURL(file),
        })),
      ]);
    aRef.current.value = null;
  }, [fileInput]);

  const remove = (index) => {
    const tempFiles = [...files];
    tempFiles.splice(index, 1);
    setFiles(tempFiles);
  };

  const upload = () => {
    // console.log(
    //   pictures.map((picture) => ({
    //     ...picture,
    //     filename: ids.find((i) => i.name === picture.filename).id,
    //   }))
    // );
    // Promise.all(
    //   pictures.map((p) =>
    //     postPicture({
    //       file: [{ id: p.id }],
    //       title: p.title,
    //       description: p.description,
    //       album: p.album.set.length ? [{ id: p.album.set[0] }] : [],
    //       tags: p.tags.set.map((t) => ({ id: t })),
    //       display: p.display,
    //       home: p.home,
    //     })
    //   )
    // ).then(() => console.log("OK"));
    setUploading(0);
    const formData = new FormData();
    files.forEach(({ file }) => formData.append("files", file, file.name));
    postFiles(formData, setUploading).then((data) => {
      Promise.all(data.map((d) => postPicture({ file: [{ id: d.id }] }))).then(
        () => {
          setFiles([]);
          setUploading(null);
        }
      );
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <StyledInput
        ref={aRef}
        multiple
        id="files"
        type="file"
        accept="image/*"
        onChange={(e) => setFileInput([...e.target.files])}
      />
      {uploading !== null && (
        <Progress>
          <LinearProgress
            variant="determinate"
            value={uploading}
            sx={{ marginTop: "50px" }}
          />
          <CircularProgress sx={{ margin: "auto" }} />
        </Progress>
      )}
      <StyledButtons>
        <Button
          variant="outlined"
          type="button"
          disabled={uploading !== null}
          onClick={() => document.getElementById("files").click()}
          endIcon={<AddCircleIcon />}
        >
          Ajouter
        </Button>
        <Button
          variant="outlined"
          type="button"
          disabled={!files.length || uploading !== null}
          onClick={() => upload()}
          startIcon={<CloudUploadIcon />}
        >
          Télécharger
        </Button>
      </StyledButtons>
      <ImageList cols={4}>
        {files.map(({ file, url }, index) => (
          <ImageListItem key={`file${index}`}>
            <img src={`${url}`} alt={file.name} loading="lazy" />
            <ImageListItemBar
              title={file.name}
              subtitle={""}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label="Supprimer"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default Upload;
