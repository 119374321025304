import "./App.scss";
import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Gallery from "./pages/Gallery";
import Admin from "./pages/Admin";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const App = () => {
  const [open, setOpen] = useState(false);
  const [chipList, setChipList] = useState([]);

  // useEffect(() => {
  //   setChipList(
  //     allTags.map((tag) => ({ label: tag, selected: false, active: true }))
  //   );
  // }, [allTags]);

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
        <AuthProvider>
          <BrowserRouter>
            <Header setOpen={setOpen} />
            <SideMenu
              open={open}
              setOpen={setOpen}
              chipList={chipList}
              setChipList={setChipList}
            />
            <Routes>
              <Route index element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/info" element={<Info />} />
              <Route
                path="/gallery"
                element={
                  <Gallery chipList={chipList} setChipList={setChipList} />
                }
              />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
