import styled from "styled-components";
import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import postMail from "../services/postMail.php";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Page = styled.div`
  min-height: calc(100% - 50px);
  margin: auto;
  padding: 0 5px;
  @media (min-width: 641px) {
    padding: 0 50px;
  }
`;

const Presentation = styled.div`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 961px) {
    flex-direction: row;
  }
  padding: 20px;
  gap: 20px;
`;

const Image = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  flex: 1;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0 20px;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 20px;
  gap: 10px;
`;

const Info = () => {
  const meta = {
    title: "SHOT BY BEE | Informations",
    description:
      "Photographe passionnée et créative, j'ai à cœur d'immortaliser la magie et l'émotion de vos instants précieux, qu'ils soient professionnels ou privés.",
    image: "https://shotbybee.fr/assets/profile_resized.jpg",
    url: "https://shotbybee.fr",
  };

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);
  const [sending, setSending] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    type: "",
    message: "",
  });

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.validity.valid) {
      setNameError(false);
    } else {
      setNameError(true);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.validity.valid) {
      setMessageError(false);
    } else {
      setMessageError(true);
    }
  };

  const send = () => {
    setSending(true);
    axios
      .post(postMail, { name, email, message })
      .then(() => {
        setOpenMail(false);
        setName("");
        setEmail("");
        setMessage("");
        setSnackbar({
          open: true,
          type: "success",
          message: "Message envoyé !",
        });
      })
      .catch((err) => {
        console.error(err);
        setSnackbar({
          open: true,
          type: "success",
          message:
            "Une erreur est survenue. Votre message n'a pas pu être délivré.",
        });
      })
      .finally(() => setSending(false));
  };

  return (
    <HelmetProvider>
      <Page>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:locale" content="fr_FRANCE" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={meta.image} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
        </Helmet>
        <Presentation>
          <Image>
            <img
              style={{ width: "100%", maxWidth: "400px" }}
              className="img"
              src={`https://shotbybee.fr/assets/profile_resized.jpg`}
              alt="Profile"
              loading="lazy"
            />
          </Image>
          <Text>
            <p>
              Chaque image raconte une histoire, elles sont importantes pour les
              souvenirs et primordiales pour la communication.
            </p>
            <p>
              Qu'il s'agisse de renforcer l'image de votre entreprise, de
              documenter un événement, une célébration, un concert, de capturer
              des moments en famille… Il est important pour moi d'obtenir un
              résultat qui reflètent votre identité, votre histoire avec
              authenticité et sensibilité.
            </p>
            <p>
              Photographe passionnée et créative, j'ai à cœur d'immortaliser la
              magie et l'émotion de vos instants précieux, qu'ils soient
              professionnels ou privés.
            </p>
            <p>
              Pour toute question ou proposition,
              <Button
                variant="text"
                style={{ color: "white" }}
                size="small"
                endIcon={<SendIcon />}
                onClick={() => setOpenMail(true)}
              >
                contactez-moi !
              </Button>
            </p>
          </Text>
        </Presentation>
        <Dialog
          open={openMail}
          onClose={() => setOpenMail(false)}
          fullWidth
          maxWidth="xs"
        >
          <Contact>
            <h3>Me contacter:</h3>
            <TextField
              size="small"
              label="Nom"
              defaultValue={name}
              onChange={handleNameChange}
              required
              error={nameError}
              helperText={nameError ? "Champ obligatoire" : ""}
              disabled={sending}
            />
            <TextField
              size="small"
              label="Email"
              defaultValue={email}
              onChange={handleEmailChange}
              inputProps={{
                type: "email",
              }}
              required
              error={emailError}
              helperText={emailError ? "Email invalide" : ""}
              disabled={sending}
            />
            <TextField
              size="small"
              label="Message"
              defaultValue={message}
              multiline
              rows={4}
              onChange={handleMessageChange}
              required
              error={messageError}
              helperText={messageError ? "Champ obligatoire" : ""}
              disabled={sending}
            />
            <Button
              size="small"
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={() => send()}
              disabled={
                sending ||
                !name ||
                nameError ||
                !email ||
                emailError ||
                !message ||
                messageError
              }
            >
              Envoyer
            </Button>
          </Contact>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ open: false, type: "", message: "" })}
        >
          <Alert
            severity={snackbar.type || "info"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Page>
    </HelmetProvider>
  );
};

export default Info;
