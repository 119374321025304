import { usePictureURL } from "../hooks/usePictureURL.js";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import HomeIcon from "@mui/icons-material/Home";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";

const AlbumAccordion = ({
  album,
  pictures,
  expanded,
  setExpanded,
  setOpenUpdate,
  setSelectedPicture,
}) => {
  const { pictureURL } = usePictureURL();

  return (
    <Accordion
      expanded={expanded === album}
      onChange={(event, isExpanded) => setExpanded(isExpanded, album)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>{album}</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {pictures.length}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {pictures
            .sort((a, b) => {
              var A = a.attributes.title?.toUpperCase();
              var B = b.attributes.title?.toUpperCase();
              return A < B ? -1 : A > B ? 1 : 0;
            })
            .map((picture, index) => (
              <div key={index}>
                {!!index && <Divider variant="inset" component="li" />}
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar
                      alt={picture.attributes.title}
                      src={pictureURL(picture, "xs")}
                      variant="square"
                      sx={{
                        width: 70,
                        height: 70,
                        marginRight: 1,
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Stack direction="row" spacing={1}>
                          {picture.attributes.home && (
                            <HomeIcon fontSize="small" />
                          )}
                          {!picture.attributes.display && (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                          <div>
                            {picture.attributes.title ||
                              (picture.attributes.file.data
                                ? picture.attributes.file.data.attributes.name
                                : "Pas de fichier ni de nom")}
                            {picture.attributes.album.data &&
                            picture.attributes.album.data.attributes.name !==
                              album
                              ? ` (album: ${picture.attributes.album.data.attributes.name})`
                              : ""}
                          </div>
                        </Stack>
                        <Typography
                          sx={{ display: "inline", color: "text.secondary" }}
                          variant="body2"
                        >
                          {picture.attributes.description}
                        </Typography>
                        <Stack direction="row" gap={0.5} flexWrap="wrap">
                          {picture.attributes.tags.data.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag.attributes.name}
                              variant="outlined"
                              size="small"
                            />
                          ))}
                        </Stack>
                      </>
                    }
                  />
                  <IconButton
                    size="large"
                    onClick={() => {
                      setSelectedPicture(picture);
                      setOpenUpdate();
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </ListItem>
              </div>
            ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AlbumAccordion;
