import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { API, BEARER } from "../constant";
import { getToken, setToken, removeToken } from "../helpers";

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const authToken = getToken();

  const login = async ({ identifier, password }) => {
    setIsLoading(true);
    const response = axios
      .post(`${API}/api/auth/local`, { identifier, password })
      .then(({ data }) => {
        setError();
        setToken(data.jwt);
        setUser(data.user);
      })
      .catch((err) => {
        console.error(err);
        setError("Identifiant ou mot de passe incorrect");
      })
      .finally(() => setIsLoading(false));
    return response;
  };

  const logout = () => {
    removeToken();
    setUser();
  };

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    const response = axios
      .get(`${API}/api/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      })
      .then(({ data }) => setUser(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
    return response;
  };

  useEffect(() => {
    if (authToken) {
      fetchLoggedInUser(authToken);
    } else {
      setUser();
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        error,
        setUser,
        setIsLoading,
        setError,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
