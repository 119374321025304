import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePictureURL } from "../hooks/usePictureURL.js";
import { getPictures } from "../services/PictureService.js";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CircularProgress from "@mui/material/CircularProgress";

const Title = styled.div`
  color: #ffffff;
  font-size: x-small;
  font-weight: bold;
  @media (min-width: 641px) {
    font-size: large;
  }
`;

const Progress = styled.div`
  z-index: 999;
  position: absolute;
  left: 0;
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const HomeList = () => {
  const navigate = useNavigate();
  const { pictureURL } = usePictureURL();

  const [pictures, setPictures] = useState([]);

  useEffect(() => {
    getPictures({ home: true }).then((data) => setPictures(data?.data || []));
  }, []);

  // const [filesList, setFilesList] = useState([]);

  // useEffect(() => {
  //   let toAdd = [
  //     { rows: 2, cols: 2 },
  //     {},
  //     {},
  //     { cols: 2 },
  //     { cols: 2 },
  //     { rows: 2, cols: 2 },
  //     {},
  //     {},
  //   ];
  //   setFilesList(
  //     pictures.map((data) => {
  //       toAdd.push(toAdd[0]);
  //       return { ...data, ...toAdd.shift() };
  //     })
  //   );
  // }, [pictures]);

  return !!pictures.length ? (
    <ImageList
      sx={{
        width: "96%",
        paddingTop: "20px",
        marginY: "0px",
        marginX: "auto",
        paddingBottom: "20px",
        zIndex: 0,
      }}
      variant="masonry"
      cols={4}
      // rowHeight={200}
      gap={8}
    >
      {pictures.map((picture, index) => (
        <ImageListItem
          className="frame"
          key={`home${index}`}
          // cols={file.cols || 1}
          // rows={file.rows || 1}
          onClick={() =>
            navigate(
              `/gallery?name=${picture.attributes.album.data.attributes.name}`
            )
          }
        >
          <div className="folder">
            <Title>{picture.attributes.album.data.attributes.name}</Title>
          </div>
          <img
            className="img"
            src={pictureURL(picture, "sm")}
            alt={picture.attributes.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  ) : (
    <Progress>
      <CircularProgress />
    </Progress>
  );
};

export default HomeList;
