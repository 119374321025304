import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAlbums } from "../services/AlbumService.js";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ChipList from "../components/ChipList";

export default function TemporaryDrawer({
  open,
  setOpen,
  chipList,
  setChipList,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [albumsTitles, setAlbumsTitles] = useState([]);

  useEffect(() => {
    getAlbums().then((data) => {
      setAlbumsTitles(data?.map((d) => d.attributes.name) || []);
    });
  }, []);

  const selectChip = (label) => {
    setChipList((c) =>
      c.map((chip) => ({
        ...chip,
        selected: chip.label === label ? !chip.selected : chip.selected,
      }))
    );
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: "black",
        },
      }}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        // onClick={() => setOpen(false)}
      >
        <List>
          <ListItem
            disablePadding
            onClick={() => {
              setOpen(false);
              navigate("/");
            }}
          >
            <ListItemButton>
              <ListItemText primary="Accueil" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              setOpen(false);
              navigate("/gallery");
            }}
          >
            <ListItemButton>
              <ListItemText primary="Toutes les photos" />
            </ListItemButton>
          </ListItem>
          {albumsTitles.map((albumTitle) => (
            <ListItem
              key={albumTitle}
              disablePadding
              onClick={() => {
                setOpen(false);
                navigate(`/gallery?name=${albumTitle}`);
              }}
            >
              <ListItemButton>
                <ListItemText primary={albumTitle} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {pathname === "/gallery" && (
          <ChipList chipList={chipList} selectChip={selectChip} />
        )}
      </Box>
    </Drawer>
  );
}
