import styled from "styled-components";
import { useMemo, useState, useEffect } from "react";
import { usePictureURL } from "../hooks/usePictureURL.js";
import {
  getPicture,
  putPicture,
  deletePicture,
} from "../services/PictureService.js";
import { deleteFile } from "../services/FileService.js";
import { getAlbums, postAlbum } from "../services/AlbumService.js";
import { getTags, postTag } from "../services/TagService.js";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { green, yellow } from "@mui/material/colors";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Progress = styled.div`
  z-index: 999;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const SwitchList = styled.div`
  margin: ${({ $expanded }) => ($expanded ? "15px 0" : "15px 0 0")};
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: 0.75rem;
  line-height: 0;
`;

const Field = styled(TextField)`
  margin-bottom: 15px !important;
  width: 100%;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
`;

const StyledFields = styled.div`
  display: ${({ $expanded }) => ($expanded ? "block" : "none")};
`;

const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)`
  transform: ${({ $expanded }) =>
    $expanded ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.5s ease-out;
`;

const DataCard = ({
  selectedPicture,
  setUpdated,
  setDeleted,
  close,
  setSnackbar,
  isOpen = true,
}) => {
  const { pictureURL } = usePictureURL();

  const defaultValues = useMemo(
    () => ({
      title: selectedPicture.attributes.title || "",
      description: selectedPicture.attributes.description || "",
      album: selectedPicture.attributes.album.data?.attributes.name || "",
      tags:
        selectedPicture.attributes.tags.data?.map((t) => t.attributes.name) ||
        [],
      display: selectedPicture.attributes.display || false,
      home: selectedPicture.attributes.home || false,
    }),
    [selectedPicture]
  );

  const [albumsList, setAlbumsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [title, setTitle] = useState(defaultValues.title);
  const [description, setDescription] = useState(defaultValues.description);
  const [album, setAlbum] = useState(defaultValues.album);
  const [tags, setTags] = useState(defaultValues.tags);
  const [display, setDisplay] = useState(defaultValues.display);
  const [home, setHome] = useState(defaultValues.home);
  const [modified, setModified] = useState(false);
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [expanded, setExpanded] = useState(isOpen);

  useEffect(() => {
    getAlbums().then((d) =>
      setAlbumsList(d.map(({ id, attributes: { name } }) => ({ id, name })))
    );
    getTags().then((d) =>
      setTagsList(d.map(({ id, attributes: { name } }) => ({ id, name })))
    );
  }, []);

  useEffect(() => {
    setModified(
      !(
        defaultValues.title === title &&
        defaultValues.description === description &&
        defaultValues.album === album &&
        defaultValues.tags.length === tags.length &&
        defaultValues.tags.every(
          (v) =>
            defaultValues.tags.filter((e) => e === v).length ===
            tags.filter((e) => e === v).length
        ) &&
        defaultValues.display === display &&
        defaultValues.home === home
      )
    );
  }, [defaultValues, title, description, album, tags, display, home]);

  const update = () => {
    setUpdating(true);
    Promise.all([
      ...tags
        .filter((t) => !tagsList.find(({ name }) => name === t))
        .map((name) => postTag(name)),
      !album || albumsList.find(({ name }) => name === album)
        ? { data: { id: albumsList.find(({ name }) => name === album)?.id } }
        : postAlbum(album),
    ]).then((values) => {
      const addAlbum = values.pop().data.id;
      const addTags = [
        ...values.map((value) => value.data.id),
        ...tagsList
          .filter(({ name }) => tags.find((t) => name === t))
          .map(({ id }) => id),
      ];

      putPicture(
        {
          ...(defaultValues.title !== title ? { title } : {}),
          ...(defaultValues.description !== description ? { description } : {}),
          ...(defaultValues.album !== album
            ? { album: { set: [...(addAlbum ? [addAlbum] : [])] } }
            : {}),
          ...(defaultValues.tags.length === tags.length &&
          defaultValues.tags.every(
            (v) =>
              defaultValues.tags.filter((e) => e === v).length ===
              tags.filter((e) => e === v).length
          )
            ? {}
            : { tags: { set: addTags } }),
          ...(defaultValues.display !== display ? { display } : {}),
          ...(defaultValues.home !== home ? { home } : {}),
        },
        selectedPicture.id
      ).then(() =>
        getPicture(selectedPicture.id).then((data) => {
          setUpdated(data);
          setUpdating(false);
          setSnackbar({
            open: true,
            type: "success",
            message: "Les données ont été mises à jour",
          });
          close();
        })
      );
    });
  };

  const remove = () => {
    setUpdating(true);
    Promise.all([
      deletePicture(selectedPicture.id),
      deleteFile(selectedPicture.attributes.file.data.id),
    ]).then(() => {
      setDeleted(selectedPicture.id);
      setUpdating(false);
      setSnackbar({
        open: true,
        type: "success",
        message: "L'image a bien été supprimée'",
      });
      close();
    });
  };

  return (
    <>
      <Card
        className="removeScrollBar"
        sx={{
          width: 345,
          alignSelf: "start",
          position: "relative",
          overflowY: "scroll",
        }}
      >
        {updating && (
          <Progress>
            <CircularProgress />
          </Progress>
        )}
        <CardHeader
          sx={{
            display: "bloc",
            overflow: "hidden",
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
          }}
          avatar={
            <Avatar
              sx={{
                bgcolor: modified ? yellow[500] : green[500],
              }}
            >
              <AssignmentIcon />
            </Avatar>
          }
          action={<></>}
          title={
            selectedPicture.attributes.file.data
              ? selectedPicture.attributes.file.data.attributes.name
              : "Pas de fichier"
          }
          subheader=""
        />

        {selectedPicture.attributes.file.data && (
          <CardMedia
            sx={{ height: 140, backgroundSize: "contain" }}
            image={pictureURL(selectedPicture, "xs")}
          />
        )}
        <CardContent>
          <SwitchList $expanded={expanded}>
            <FormControlLabel
              control={
                <Switch
                  color="success"
                  defaultChecked={defaultValues.home}
                  onChange={(event) => setHome(event.target.checked)}
                />
              }
              label={<Label>Accueil</Label>}
              labelPlacement="end"
            />
            <IconButton onClick={() => setExpanded(!expanded)}>
              <StyledKeyboardArrowUpIcon $expanded={expanded} />
            </IconButton>
            <FormControlLabel
              control={
                <Switch
                  color="success"
                  defaultChecked={defaultValues.display}
                  onChange={(event) => setDisplay(event.target.checked)}
                />
              }
              label={<Label>Affichée</Label>}
              labelPlacement="start"
            />
          </SwitchList>
          <StyledFields $expanded={expanded}>
            <Field
              label="Nom"
              variant="outlined"
              type="text"
              size="small"
              defaultValue={defaultValues.title}
              onChange={(event) => setTitle(event.target.value)}
            />
            <Field
              label="Description"
              multiline
              rows={2}
              size="small"
              defaultValue={defaultValues.description}
              onChange={(event) => setDescription(event.target.value)}
            />
            <Autocomplete
              freeSolo
              disableClearable
              size="small"
              options={albumsList.map((a) => a.name)}
              defaultValue={defaultValues.album}
              onChange={(event, values) => setAlbum(values)}
              renderInput={(params) => (
                <Field
                  {...params}
                  label="Dossier"
                  variant="outlined"
                  type="text"
                  onChange={(event) => setAlbum(event.target.value)}
                />
              )}
            />
            <Autocomplete
              multiple
              freeSolo
              size="small"
              options={tagsList.map((t) => t.name)}
              defaultValue={defaultValues.tags}
              onChange={(event, values) => setTags(values)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tags" />
              )}
            />
          </StyledFields>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="success"
            onClick={() => update()}
            disabled={!modified}
          >
            Enregistrer
          </Button>
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="error"
            onClick={() => setOpen(true)}
          >
            Supprimer
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{`Es-tu sûre de vouloir supprimer cette photo?`}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <DialogContentText>
            {selectedPicture.attributes.file.data
              ? selectedPicture.attributes.file.data.attributes.name
              : "Pas de fichier"}
          </DialogContentText>
          {selectedPicture.attributes.file.data && (
            <Image src={pictureURL(selectedPicture, "xs")} />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="error"
            onClick={() => {
              remove();
              setOpen(false);
            }}
          >
            Supprimer
          </Button>
          <Button
            size="small"
            type="button"
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataCard;
