import axios from "axios";
import { API, BEARER } from "../constant";
import { getToken } from "../helpers";

const token = getToken();

export const getFiles = async () => {
  const response = await axios
    .get(`${API}/api/upload/files`, {
      headers: { Authorization: `${BEARER} ${token}` },
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return response;
};

export const postFiles = async (formData, setUploading) => {
  const response = await axios
    .post(`${API}/api/upload`, formData, {
      headers: { Authorization: `${BEARER} ${token}` },
      onUploadProgress: (e) =>
        setUploading(Math.round((e.loaded * 100) / e.total)),
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return response;
};

export const deleteFile = async (id) => {
  const result = await axios
    .delete(`${API}/api/upload/files/${id}`, {
      headers: { Authorization: `${BEARER} ${token}` },
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(err);
    })
    .finally(() => {});
  return result;
};
