import styled from "styled-components";
import { usePictureURL } from "../hooks/usePictureURL.js";
import Divider from "@mui/material/Divider";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const Album = styled.h3`
  color: #ffffff;
`;

const Title = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: x-small;
  font-weight: bold;
  @media (min-width: 641px) {
    font-size: large;
  }
`;

const GalleryList = ({ album, pictures }) => {
  const { pictureURL } = usePictureURL();

  // const [filesList, setFilesList] = useState([]);

  // useEffect(() => {
  //   let toAdd = [
  //     { rows: 2, cols: 2 },
  //     {},
  //     {},
  //     { cols: 2 },
  //     { cols: 2 },
  //     { rows: 2, cols: 2 },
  //     {},
  //     {},
  //   ];
  //   setFilesList(
  //     pictures.map((data) => {
  //       toAdd.push(toAdd[0]);
  //       return { ...data, ...toAdd.shift() };
  //     })
  //   );
  // }, [pictures]);

  return (
    !!pictures.length && (
      <>
        <Album>
          <Divider>{album}</Divider>
        </Album>
        <ImageList
          sx={{
            width: "96%",
            marginY: "0px",
            marginX: "auto",
            paddingBottom: "20px",
            zIndex: 0,
          }}
          variant="masonry"
          cols={7}
          // rowHeight={200}
          gap={8}
        >
          {pictures.map((picture) => (
            <ImageListItem
              key={`picture${picture.id}`}
              className="frame"
              // cols={picture.cols || 1}
              // rows={picture.rows || 1}
              onClick={() => document.getElementById(picture.id).click()}
            >
              <div className="filename">
                <Title>{picture.attributes.title}</Title>
              </div>
              <img
                className="img"
                src={pictureURL(picture, "sm")}
                alt={picture.name}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </>
    )
  );
};

export default GalleryList;
