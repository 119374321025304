import styled from "styled-components";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { getPictures } from "../services/PictureService.js";
import GalleryList from "../components/GalleryList";
import GalleryView from "../components/GalleryView";
import CircularProgress from "@mui/material/CircularProgress";

document.addEventListener("contextmenu", (event) => event.preventDefault());

const Page = styled.div`
  min-height: calc(100% - 50px);
  margin: auto;
  padding: 0 5px;
  @media (min-width: 641px) {
    padding: 0 50px;
  }
`;

const Progress = styled.div`
  z-index: 999;
  position: absolute;
  left: 0;
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
`;

const Gallery = ({ chipList, setChipList }) => {
  const meta = {
    title: "SHOT BY BEE | Galerie",
    description:
      "Photographe passionnée et créative, j'ai à cœur d'immortaliser la magie et l'émotion de vos instants précieux, qu'ils soient professionnels ou privés.",
    image: "https://shotbybee.fr/assets/profile_resized.jpg",
    url: "https://shotbybee.fr",
  };

  const { search } = useLocation();

  // const [gallery, setGallery] = useState("");
  const [pictures, setPictures] = useState([]);
  const [albums, setAlbums] = useState([]);

  // useEffect(() => {
  //   setGallery(new URLSearchParams(search).get("name"));
  // }, [search, setGallery]);

  useEffect(() => {
    getPictures({
      album: search ? new URLSearchParams(search).get("name") : "full",
      count: true,
    }).then(
      ({
        meta: {
          pagination: { total },
        },
      }) =>
        Promise.all(
          [...Array((total - (total % 100)) / 100 + 1).keys()].map((i) =>
            getPictures({
              album: search ? new URLSearchParams(search).get("name") : "full",
              page: i + 1,
            })
          )
        ).then((data) => {
          const flatData = data.reduce(
            (acc, curr) => [...acc, ...curr.data],
            []
          );
          setPictures(flatData);
          setAlbums([
            ...new Set(
              flatData.reduce(
                (acc, curr) => [
                  ...acc,
                  curr.attributes.album.data.attributes.name,
                ],
                []
              )
            ),
          ]);
          setChipList(
            [
              ...new Set(
                flatData.reduce(
                  (acc, curr) => [
                    ...acc,
                    ...curr.attributes.tags.data.map(
                      (tag) => tag.attributes.name
                    ),
                  ],
                  []
                )
              ),
            ].map((tag) => ({ label: tag, selected: false, disabled: false }))
          );
        })
    );

    // getPictures({
    //   album: search ? new URLSearchParams(search).get("name") : "full",
    // }).then(({ data }) => {
    //   setPictures(data);
    //   setAlbums([
    //     ...new Set(
    //       data.reduce(
    //         (acc, curr) => [...acc, curr.attributes.album.data.attributes.name],
    //         []
    //       )
    //     ),
    //   ]);
    //   setChipList(
    //     [
    //       ...new Set(
    //         data.reduce(
    //           (acc, curr) => [
    //             ...acc,
    //             ...curr.attributes.tags.data.map((tag) => tag.attributes.name),
    //           ],
    //           []
    //         )
    //       ),
    //     ].map((tag) => ({ label: tag, selected: false, disabled: false }))
    //   );
    // });
  }, [search, setChipList]);

  // useEffect(() => {
  //   let test = pictures.filter((picture) =>
  //     picture.attributes.tags.data.some(({ attributes }) =>
  //       chipList
  //         .filter((chip) => chip.selected)
  //         .map((chip) => chip.label)
  //         .includes(attributes.name)
  //     )
  //   );
  // }, [chipList]);

  const picturesByAlbum = (album) => {
    return pictures.filter(
      (picture) => picture.attributes.album.data.attributes.name === album
    );
  };

  return (
    <HelmetProvider>
      <Page>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:locale" content="fr_FRANCE" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={meta.image} />
          <meta property="og:url" content={meta.url} />
          <meta property="og:description" content={meta.description} />
        </Helmet>
        {!!albums.length ? (
          albums.map((album) => (
            <div key={album}>
              <GalleryList album={album} pictures={picturesByAlbum(album)} />
              <GalleryView album={album} pictures={picturesByAlbum(album)} />
            </div>
          ))
        ) : (
          <Progress>
            <CircularProgress />
          </Progress>
        )}
      </Page>
    </HelmetProvider>
  );
};

export default Gallery;
