import styled from "styled-components";
import React from "react";
import { useAuthContext } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from "@mui/icons-material/Instagram";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const Component = styled.div`
  z-index: 999;
  background-color: black;
  height: 50px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 5px #00000050;
  padding: 0 10px;
`;

const Group = styled.div`
  display: flex;
  justify-content: center;
  &:first-child {
    justify-content: start;
    width: 45%;
  }
  &:last-child {
    justify-content: end;
    width: 45%;
  }
  align-items: center;
  gap: 10px;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 641px) {
    flex-direction: row;
  }
`;

const StyledName = styled.div`
  color: #ffffff;
  cursor: pointer;
  @media (min-width: 641px) {
    margin-right: 5px;
  }
`;

const StyledDescription = styled.div`
  font-size: 0.75rem;
  margin-top: 5px;
  color: #ffffffb3;
  cursor: default;
  line-height: 1;
  @media (min-width: 641px) {
    line-height: 1.1rem;
  }
`;

const Link = styled.div`
  cursor: pointer;
  color: #ffffff !important;
  text-decoration: none;
  line-height: 0px;
`;

const ExternalLink = styled.a`
  color: #ffffff !important;
  text-decoration: none;
  line-height: 0px;
`;

const Header = ({ setOpen }) => {
  const { user, logout } = useAuthContext();

  const navigate = useNavigate();

  return (
    <Component>
      <Group>
        <IconButton id="long-button" onClick={() => setOpen(true)}>
          <MenuIcon />
        </IconButton>
        {/* <List sx={{ color: "white", cursor: "pointer" }}>
          <ListItemText onClick={() => navigate("/")}>
            <ListItemText
              primary="SHOT BY BEE"
              secondary={
                pathname === "/gallery" ? gallery || "Galerie" : "Photographies"
              }
            />
          </ListItemText>
        </List> */}
        <StyledTitle>
          <StyledName onClick={() => navigate("/")}>SHOT BY BEE</StyledName>
          <StyledDescription>Photographies</StyledDescription>
        </StyledTitle>
      </Group>
      {user && (
        <Group>
          <Link>
            <AdminPanelSettingsIcon onClick={() => navigate("/admin")} />
          </Link>
          <Link>
            <PowerSettingsNewIcon onClick={() => logout()} />
          </Link>
        </Group>
      )}
      <Group>
        <Link onClick={() => navigate("/info")}>INFO</Link>
        <ExternalLink
          href="https://www.instagram.com/shotbybee/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </ExternalLink>
      </Group>
    </Component>
  );
};

export default Header;
