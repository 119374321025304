import styled from "styled-components";
import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

const Component = styled.div`
  padding: 0 2px;
`;

const StackModified = styled(Stack)`
  padding-top: 10px;
  justify-content: center;
  gap: 5px;
`;

const ChipList = ({ chipList, selectChip }) => {
  return (
    <Component>
      <StackModified direction="row" flexWrap="wrap">
        {chipList.map(({ label, selected, disabled }) => (
          <Chip
            key={label}
            label={label}
            variant={selected ? "outlined" : ""}
            onClick={() => selectChip(label)}
            disabled={disabled}
          />
        ))}
      </StackModified>
    </Component>
  );
};

export default ChipList;
