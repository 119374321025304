import { API } from "../constant";

export const usePictureURL = () => {
  const pictureURL = (picture, size) => {
    if (picture.attributes.file.data)
      switch (size) {
        case "lg":
          return `${API}${picture.attributes.file.data.attributes.formats.large.url}`;
        case "md":
          return `${API}${picture.attributes.file.data.attributes.formats.medium.url}`;
        case "sm":
          return `${API}${picture.attributes.file.data.attributes.formats.small.url}`;
        case "xs":
          return `${API}${picture.attributes.file.data.attributes.formats.thumbnail.url}`;
        default:
          return `${API}${picture.attributes.file.data.attributes.url}`;
      }
    else return "";
  };

  return { pictureURL };
};
